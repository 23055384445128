import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
export default function CategoryRow({ category = null }) {
  const image = getImage(category.category.image);
  // console.log(category)
  return (
    <Link
      to={`/${category.category.slug}`}
      className="block group hover:bg-blue-700 p-2 md:p-4 border-b flex  w-full"
    >
    {category.category.image && category.category.image.extension !== "svg" && image && (
      <div className="lg:mr-4 mr-2 rounded group-hover:bg-white  p-1">
        <GatsbyImage className={'cat-image  '} image={image} alt={category.category.name} />
      </div>
    )}
    {category.category.image && category.category.image.extension === "svg" && (
      <div className="lg:mr-4 mr-2  rounded group-hover:bg-white  p-1">
        <img  className={'cat-image group-hover:bg-white '} src={category.category.image.url} alt={category.category.name} />
      </div>
    )}
      <div>
        <p className="font-bold text-lg lg:text-4xl mb-1 text-black text-blue-900 group-hover:text-white w-full">
          {category.category.name}
        </p>
        {category.category.description ? (
          <p className="text-sm lg:text-base mb-0 group-hover:text-white text-blue-900 w-full ">
            {category.category.description}
          </p>
        ) : null}
      </div>
    </Link>
  );
}
