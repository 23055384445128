import React from "react";
import { AuthContext } from "../context/auth-context";

export default function DisplaySummary() {
  const { profile, user } = React.useContext(AuthContext);
  return (
    <>
      {profile !== null && user !== null ? (
        <div
          className={
            "container mx-auto font-sans py-4 px-4 text-xs text-gray-700 py-6 text-center lg:text-left"
          }
        >
          <p className={"mb-4 text-center"}>
            You have have {profile.wins} wins and {profile.loses} failed
            attempts out of {profile.totalGames} games. Your average score is{" "}
            {Math.round(profile.average)} per game. Total Score so far is{" "}
            {profile.totalScore}.
          </p>
        </div>
      ) : null}
    </>
  );
}
